import React from "react"
import {useTranslation} from "react-i18next";
import * as mapStyle from "../mapStyle";

export const OfficeCanada = () => {

    const {t} = useTranslation();
    return (
        <div className={'map-container'} style={mapStyle.mapContainer} id="map-canada">
            <h4>{t('app.block.canada')}</h4>
            <iframe
                id="map-canada" title="map-canada"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2872.534000466432!2d-79.4546549!3d43.948317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ad5ccbeeaaaa9%3A0x8b54717ab814c6b!2s13085%20Yonge%20St%20%23310%2C%20Richmond%20Hill%2C%20ON%20L4E%200K2%2C%20Kanada!5e0!3m2!1sde!2sde!4v1654787640799!5m2!1sde!2sde"
                width="400" height="400" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}