import React, {useRef} from "react"
import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import emailjs from "@emailjs/browser";
import Button from "@mui/material/Button";
import * as contactFormStyle from "./contactFormStyle";

export const ContactForm = (provided) => {
    const CssTextField = styled(TextField)({
        ...provided,
        '& .MuiInputBase-input': {
            width: '350px',
        },
        '& label.Mui-focused': {
            color: '#203B72',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#203B72',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#203B72',
            },
            '&:hover fieldset': {
                borderColor: '#203B72',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#203B72',
            },
        },
    });
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        // @ts-ignore
        emailjs.sendForm('service_bbujfkt', 'template_9wyxs0r', form.current, 'tscejs6eMZD21MfPK')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <div className={'contact-form-component'} id="contact-form" style={contactFormStyle.contactFormContainer}>
            <form style={contactFormStyle.contactForm} ref={form} onSubmit={sendEmail}>
                <CssTextField className={'contact-form-field'} style={contactFormStyle.textField}
                           type="text" name="first_last_name" required label="First and Last Name" variant="standard" />
                <CssTextField className={'contact-form-field'} style={contactFormStyle.textField} type="email" name="email" required label="Email" variant="standard" />
                <CssTextField className={'contact-form-field'} style={contactFormStyle.textField} type="text" name="company_name"  label="Company Name" variant="standard" />
                <CssTextField className={'contact-form-field'} style={contactFormStyle.textField} type="text" name="phone_number"  label="Phone Number" variant="standard" />
                <TextareaAutosize className={'contact-form-field'}
                    style={contactFormStyle.textArea}
                    required
                    name="message"
                    minRows={5}
                    placeholder="Your message to us *"
                />
                <Button style={contactFormStyle.formButton} type="submit" value="Send" className="qes-primary-button" variant="outlined">Send</Button>
            </form>
        </div>
    )
}