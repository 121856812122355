// styles
export const mapContainer = {
    margin: '10px 10px',
    padding: '0 0 10px',
    textAlign: 'center',
    scrollMarginTop: '70px',
    display: 'block',
    background: "rgb(252, 252, 252)",
}


export const textField = {
    display: 'block',
    width: '350px',
    margin: '10px auto'
}
export const textArea = {
    display: 'block',
    width: '350px',
    margin: '30px auto 10px'
}
export const formButton = {
    display: 'block',
    width: 'fit-content',
    margin: '10px auto 10px',
}
const ContactFormStyles = () => {

}
export default ContactFormStyles