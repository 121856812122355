import React from "react"
import * as mainStyle from "../styles/styles";
import Container from "react-bootstrap/Container";
import Logo from "../images/logo/logo2.svg";
import BackToTop from 'react-back-to-top-button';
import {ContactForm} from "../components/Contact/contactForm";
import {Footer} from "../components/Footer/footer"
import {OfficeGermany} from "../components/Maps/OfficeGermany/map.js";
import {OfficeCanada} from "../components/Maps/OfficeCanada/map.js";
import {OfficeSerbia} from "../components/Maps/OfficeSerbia/map.js";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {QesNavbar} from "../components/Navbar/navbar"

export default function Contact() {

    // @ts-ignore
    return (
        <div>
            <main style={mainStyle.pageStyles}>
                <title>Quant Edge Solutions | Contact</title>
                <QesNavbar/>
                <link rel="icon" type="image/x-icon" href={Logo}/>

                <BackToTop
                    showAt={50}
                    speed={1500}
                    easing="easeInOutQuint"
                >
                    <ArrowUpwardIcon sx={{color: '#203B72'}}/>
                </BackToTop>
            </main>

            <Container>
                <ContactForm/>
                <div className={'map-component'} style={mainStyle.mapBundle}>
                    <OfficeGermany/>
                    <OfficeSerbia/>
                    <OfficeCanada/>
                </div>
                <Footer/>
            </Container>
        </div>
    )
}

