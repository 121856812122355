import React from "react"
import {useTranslation} from "react-i18next";
import * as mapStyle from "../mapStyle";

export const OfficeGermany = () => {

    const {t} = useTranslation();
    return (
        <div className={'map-container'} style={mapStyle.mapContainer} id="map-germany">
            <h4>{t('app.block.germany')}</h4>
            <iframe
                id="map-germany" title="map-germany"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10211.238050540256!2d8.64688!3d50.22078!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x282080c46edb401d!2sQuant%20Edge%20Solutions%20GmbH!5e0!3m2!1sde!2sde!4v1654787022520!5m2!1sde!2sde"
                width="400" height="400" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}