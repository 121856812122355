import React from "react"
import {useTranslation} from "react-i18next";
import * as mapStyle from "../mapStyle";

export const OfficeSerbia = () => {

    const {t} = useTranslation();
    return (
        <div className={'map-container'} style={mapStyle.mapContainer} id="map-serbia">
            <h4>{t('app.block.serbia')}</h4>
            <iframe
                id="map-serbia" title="map-serbia"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d716.7543209739802!2d22.094323848936423!3d44.06235172411553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4753f855373afa75%3A0xa4df682d91666d0b!2sHajduk%20Veljkova%208%2C%20Bor%2C%20Serbien!5e0!3m2!1sde!2sde!4v1654787697529!5m2!1sde!2sde"
                width="400" height="400" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}